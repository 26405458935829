@import '../../scss/style.scss';

.pracujemy-wrapper {
  padding: 5.2em 0;

  .jakPracuemyTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;
    overflow: hidden;
  }

  .jakPracuemyTitleShadow {
    font-family: Rubik;
    font-size: 3.75vw;
    font-weight: 700;
    color: $colorWhite;
    -webkit-text-stroke-color: $colorLightBlue;
    -webkit-text-stroke-width: 2px;
    white-space: pre;
    // text-transform: uppercase;
  }

  .jakPracuemyTitleText {
    font-family: Rubik;
    font-size: 3.34vw;
    font-weight: 700;
    color: $colorDarkBlue;
    margin-top: -0.6em;
    text-transform: uppercase;
  }

  .pracujemy-container {
    display: flex;
    // align-items: center;
    width: 100%;
    gap: 0.5%;

    .pracujemy-item {
      // height: 19em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      // margin: 0 auto;
      width: 22.25%;

      .pracujemy-item-img-wrapp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11.67vw;
        height: 11.67vw;
        background-color: $colorLightBlue;
        border-radius: 50%;

        img {
          width: 7.6vw;
          height: 7.6vw;
        }
      }
    }

    // .dots-wrapp {
    //   display: flex;
    //   align-items: center;
    // }

    .dots {
      width: 1.7vw;
      margin-top: 6em;
      transform: scale(2);

      // @media (max-width: 576px) {
      //   display: none;
      // }

    }
  }

  .pracujemy-item-description {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25vw;
    line-height: 100%;
    text-align: center;
    color: $colorDarkBlue;
    // max-width: 20.4vw;
    margin-top: 1.83em;
  }
}

@media(max-width: 1024px) {
  .pracujemy-wrapper .jakPracuemyTitleText {
    margin-top: 0;
    font-size: 4.17vw;
  }

  .pracujemy-wrapper .jakPracuemyTitleShadow {
    display: none;
  }

  .pracujemy-wrapper .pracujemy-item-description {
    font-size: 2.2vw;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item .pracujemy-item-img-wrapp {
    width: 14.67vw;
    height: 14.67vw;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item .pracujemy-item-img-wrapp img {
    width: 9.6vw;
    height: 9.6vw;
  }

  .pracujemy-wrapper .pracujemy-container .dots {
    width: 2.7vw;
    margin-top: 7em;
  }
}

@media(max-width: 576px) {

  .pracujemy-wrapper {
    padding: 12.2em 0;
  }

  .pracujemy-wrapper .jakPracuemyTitleText {
    font-size: 6.8vw;
  }

  .pracujemy-wrapper .pracujemy-container {
    flex-direction: column;
    gap: 6em;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item img {
    width: 30vw;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item {
    width: 100%;
    height: auto;
  }

  .pracujemy-wrapper .pracujemy-item-description {
    margin-top: 1.1em;
    max-width: 100%;
    font-size: 4.8vw;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item .pracujemy-item-img-wrapp {
    width: 31.67vw;
    height: 31.67vw;
  }

  .pracujemy-wrapper .pracujemy-container .pracujemy-item .pracujemy-item-img-wrapp img {
    width: 20.6vw;
    height: 20.6vw;
  }

  .dots-wrapp {
    position: relative;
  }

  .pracujemy-wrapper .pracujemy-container .dots {
    position: absolute;
    left: calc(50% - 3vw);
    margin-top: 0;
    width: 6vw;
  }
}