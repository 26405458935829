@import '../../../scss/style.scss';

.dropdown-header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    height: 1.62vw;
    background-color: $colorWhite;
    border-radius: 0.3em;
}

.dropdown-header__subtitle {
    margin-right: auto;
    padding-bottom: 0.1em;
    font-weight: 700;
    font-size: 0.85vw;
    // line-height: 100%;
    color: $colorDarkBlue;
}

.miasto-dropdown {
    position: relative;
    margin-bottom: 6%;
    width: 17vw;
    font-weight: 500;
    font-size: 0.85vw;
    line-height: 100%;
    color: $colorDarkBlue;
    cursor: pointer;
}

.dropdown-list {
    position: absolute;
    top: 108%;
    left: 0;
    text-align: center;
    width: 100%;
    background-color: $colorWhite;
    box-shadow: 0px 4px 15px rgba(12, 7, 50, 0.25);
    border-radius: 0.3em;
    overflow: hidden;
    z-index: 1;
}

.dropdown-list li {

    transition: color .2s ease-in-out,
        background-color .2s ease-in-out;

    &:hover,
    &:active {
        color: $colorWhite;
        background-color: $colorBlue;
    }
}

.dropdown-list li {
    padding: 0.6vw 1.4vw;
}

// .dropdown-list li:not(:last-child) {
//     padding: 7px 30px 7px;
// }

// .dropdown-list li:first-child {
//     padding-top: 14px;
// }

// .dropdown-list li:last-child {
//     padding: 7px 30px 14px;
// }

.city__svg {
    display: flex;
    align-items: center;
    width: 0.65vw;
    height: 0.34vw;
    transition: transform .3s ease-in-out;
}

.city__svg--up {
    transform: rotate(0deg);
}

.city__svg--down {
    transform: rotate(180deg);
}

.city__svg svg {
    // width: 0.6vw;
    // height: 0.4vw;
    width: 100%;
    height: 100%;
}

@media(max-width: 1024px) {
    .miasto-dropdown {
        width: 40%;
        font-size: 2.08vw;
    }

    .dropdown-header__subtitle {
        font-size: 2.08vw;
    }

    .dropdown-list li {
        padding: 0.91vw 2.08vw;
    }

    .dropdown-header {
        border-radius: 5px;
        height: 4.1vw;
    }

    .city__svg {
        width: 1.35vw;
        height: 1.04vw;
    }
}

@media(max-width: 576px) {
    .miasto-dropdown {
        width: 100%;
        font-size: 4vw;
    }

    .dropdown-header__subtitle {
        font-size: 4vw;
    }

    .dropdown-header {
        border-radius: 5px;
        height: 8vw;
    }

    .city__svg {
        width: 3.1vw;
        height: 3.04vw;
    }

    .dropdown-list {
        top: 9vw;
    }

    .dropdown-list li {
        padding: 1.8vw 1.6vw;
    }
}