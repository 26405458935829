$colorBlue: #4280BB;
$colorLightBlue: #E0EDF9;
$colorDarkBlue: #2D4664;
$colorWhite: #fff;
$colorBlack: #000;
$colorYellow: #F5D287;
$colorOrange: #EDA881;
$colorGreen: #078550;
$colorRed: #B10207;
$colorBlueCircle: #699DD0;

$hover_btn: 3px 4px 8px 0 rgba(0, 0, 0, 0.25);