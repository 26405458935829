@import '../../scss/style.scss';

.section-uslugi {
    padding: 6em 0;
    background-color: $colorLightBlue;

    h4 {
        margin-bottom: 0.74em;
        color: $colorBlue;
        text-align: center;
        font-family: 'Rubik';
        font-size: 3.33vw;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.tabs-wrapp {
    display: flex;
    width: 100%;
    gap: 2%;
    min-height: 40.15em;
}

.tabs {
    display: flex;
    flex-direction: column;
    width: 26%;
    gap: 1em;

    .tab {
        padding: 0.83vw 1.67vw;
        font-family: 'Rubik';
        font-size: 1.25vw;
        font-weight: 700;
        line-height: 110%;
        color: $colorBlue;
        border-radius: 1.25vw;
        background-color: $colorWhite;
        text-transform: uppercase;
        cursor: pointer;

        @include button-effect;

        &:active {
            background-color: $colorDarkBlue;
            color: $colorWhite;
          }
    }

    .tab.active-tab {
        color: $colorWhite;
        background-color: $colorBlue;
    }
}

.tab-content-wrapp {
    width: 72%;
}

.tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.4em;

    span {
        width: 100%;
        font-family: 'Rubik';
        font-size: 1.6vw;
        font-weight: 500;
        line-height: normal;
        color: $colorDarkBlue;
    }

    div {
        // padding: 0 10vw;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 29em;

        img {
            // height: 50vh;
            width: 100%;
            height: 100%;
            object-fit: contain;

        }
    }

    // .po-remonte {
    //     img {
    //         width: 90%;
    //         height: 90%;
    //     }
    // }
}

@media (max-width: 1024px) {
    .section-uslugi h4 {
        font-size: 4.17vw;
    }

    .tabs .tab {
        padding: 1.2vw 2.67vw;
        font-size: 2.08vw;
        border-radius: 24px;
    }

    .tab-content-wrapp {
        width: 61%;
    }

    .tabs {
        width: 37%;
    }

    .tab-content span {
        font-size: 2vw;
    }

    .tabs-wrapp {
        min-height: 44.15em;
    }
}

@media (max-width: 576px) {
    .section-uslugi {
        padding: 12.2em 0;
    }

    .section-uslugi h4 {
        margin-bottom: 1.15em;
        font-size: 8.8vw;
    }

    .tabs-wrapp {
        flex-direction: column;
        gap: 5em;
        height: 100%;
    }

    .tab-content {
        flex-direction: column-reverse;
        gap: 5em;
    }

    .tabs {
        gap: 2em;
    }

    .tab-content-wrapp,
    .tab-content span,
    .tab-content div,
    .tabs {
        width: 100%;
    }

    .tab-content div {
        height: 30vh;
    }

    .tabs .tab {
        font-size: 5.6vw;
        line-height: 160%;
    }

    .tab-content span {
        font-size: 5vw;
    }

    .tab-content .po-remonte img {
        width: 100%;
        height: 100%;
    }

}