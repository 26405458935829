@import '../../scss/style.scss';


.reference {
    padding: 7em 0;
    background-color: $colorWhite;

    h4 {
        margin-bottom: 0.75em;
        color: $colorBlue;
        text-align: center;
        font-family: "Rubik";
        font-size: 3.33vw;
        font-weight: 700;
        text-transform: uppercase;
    }

    .reference__wrapp-info {
        display: flex;
        width: 100%;
        // justify-content: space-evenly;
        gap: 4em;

        span {
            // align-self: center;
            width: 40%;
            font-family: 'Rubik';
            font-size: 1.88vw;
            font-weight: 700;
            line-height: normal;
            color: $colorBlue;
            text-transform: uppercase;
        }
    }


    .reference-img {
        position: relative;
        margin-left: 9em;
        border: 11px solid $colorBlue;
        width: 24%;
        height: 30em;
        z-index: 2;

        img {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 3;
        }
    }

    .back-reference-img {
        position: absolute;
        left: -17%;
        top: -11%;
        background-image: url('../../assets/images/bubbles.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 8vw;
        height: 8vw;
        z-index: 1;
    }
}

@media (max-width: 1024px) {
    .reference h4 {
        font-size: 4.17vw;
    }

    .reference .reference__wrapp-info span {
        width: 50%;
        font-size: 2.6vw;
    }

    .reference .reference-img {
        margin-left: 4em;
        width: 30%;
        height: 40em;
    }

    .reference .reference__wrapp-info {
        gap: 6em;
    }

    .reference .back-reference-img {
        left: -22%;
        top: -16%;
        width: 12vw;
        height: 12vw;
    }
}

@media (max-width: 576px) {

    .reference {
        padding: 12.2em 0 33em;
    }

    .reference h4 {
        margin-bottom: 1.3em;
        font-size: 8.8vw;
    }

    .reference .reference__wrapp-info {
        flex-direction: column;
        gap: 12em;
    }

    .reference .reference-img {
        margin: 0 auto;
        width: 60%;
        height: 80em;
    }

    .reference .back-reference-img {
        left: -30%;
        top: -20%;
        width: 32vw;
        height: 32vw;
    }

    .reference .reference__wrapp-info span {
        width: 100%;
        font-size: 6.2vw;
        text-transform: initial;
    }
}