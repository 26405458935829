.App {
  min-width: 320px;
  margin: 0 auto;
}
/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-family: -apple-technique, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  // line-height: 1;
  font-size: 0.95vw;
  // overflow-x: hidden;
  // -ms-text-size-adjust: 100%;
  // -moz-text-size-adjust: 100%;
  // -webkit-text-size-adjust: 100%;
}

// body {
//   overflow-x: hidden;
// }

input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}


.container {
  padding: 0 6.3vw;
  margin: 0 auto;
  // max-width: 1460px;
  width: 100%;

  // @media (max-width: 1920px) {
  //   padding: 0 12.05vw;
  // }

  @media (max-width: 1280px) {
    padding: 0 100px;
  }

  @media (max-width: 1024px) {
    padding: 0 50px;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }
}

/*--------------------*/