@import '../../scss/style.scss';

.input {
    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;

        .error-message {
            position: absolute;
            bottom: -1em;
            left: 1em;
            font-size: 0.85vw;
            color: $colorRed !important;
        }
    }

    &__file {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    &__file-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5em;

        svg {
            width: 1.9vw;
            height: 2.2vw;
        }
    }

    &__file-button {
        display: flex;
        align-items: center;
        color: $colorBlue;
        font-family: Rubik;
        font-size: 1.25vw;
        font-weight: 400;
        cursor: pointer;

    }

}

.input__file-button-text {
    span {
        font-weight: 500;
        font-size: 0.6em;
        color: #4280BB;
    }
}

.input__file-button-delete {
    margin-left: 1em;
    @include btn-reset;
    font-size: 1.4vw;
    color: #B10207;
    background-color: transparent;
}

@media (max-width: 1024px) {
    .input__file-icon-wrapper svg {
        width: 4.17vw;
        height: 4.95vw;
    }

    .input__file-button,
    .input__file-button-delete {
        font-size: 2.08vw;
    }
}

@media (max-width: 576px) {
    .input__file-icon-wrapper svg {
        width: 8vw;
        height: 9.5vw;
    }

    .input__file-button,
    .input__file-button-delete {
        font-size: 4vw;
    }
}