@import '../../scss/style.scss';

.rely {
    background-color: $colorBlue;
}

.relay__wrapp {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5.2em 0;
    width: 100%;
    gap: 4%;
}

.relay__wrapp-left {
    display: flex;
    flex-direction: column;
    width: 50%;
    // padding: 4em 0;

    h4 {
        margin-bottom: 0.95em;
        font-family: 'Rubik';
        font-size: 3.3vw;
        font-weight: 700;
        color: $colorYellow;
        text-transform: uppercase;
    }

    span {
        font-family: 'Rubik';
        font-size: 1.67vw;
        line-height: normal;
        font-weight: 700;
        color: $colorWhite;
    }
}

.relay__wrapp-right {
    // position: absolute;
    // top: -0.5em;
    // right: -1em;
    background-image: url('../../assets/images/Union-Foto.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 46%;
    height: 30vw;
    z-index: 2;

}

@media (max-width: 1024px) {

    .relay__wrapp {
        flex-direction: column;
    }

    .relay__wrapp-left h4 {
        font-size: 4.47vw;
    }

    .relay__wrapp-left {
        width: 100%;
    }

    .relay__wrapp-right {
        position: static;
        width: 100%;
        height: 46vh;
        background-position-x: 50%;
    }

    .relay__wrapp-left span {
        font-size: 3.17vw;
    }

    .relay__wrapp-left span:last-child {
        margin-bottom: 1em;
    }
}

@media (max-width: 576px) {


    .relay__wrapp {
        padding: 12.2em 0;
    }

    .relay__wrapp-left h4 {
        font-size: 6.8vw;
    }

    .relay__wrapp-left span {
        font-size: 4.57vw;
    }

    .relay__wrapp-right {
        height: 35vh;
    }

}