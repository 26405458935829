@import '../../scss/style.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(49, 70, 90, 0.60);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out;

    &.modal-active {
        opacity: 1;
        pointer-events: all;
    }

    & .modal-container {
        padding: 2.4vw 2.8vw;
        margin: 0 auto;
        width: 85%;
        background-color: $colorYellow;
        border-radius: 2.4vw;
        overflow: hidden;
    }

    .reglament {
        display: flex;
        flex-direction: column;
        h1 {
            margin-bottom: 0.5em;
            text-align: center;
            font-family: Rubik;
            font-size: 1.5vw;
            font-weight: 500;
            color: $colorDarkBlue;
            text-transform: uppercase;
        }

        .reglament__item {
            margin-bottom: 0.5em;
            font-family: Rubik;
            font-size: 0.9vw;
            font-weight: 600;
            line-height: 120%;
            color: $colorBlue;
            text-transform: uppercase;
        }
    }

    & .reglament__list {
        margin-bottom: 0.5em;
        position: relative;
        padding: 2vw;
        max-height: 65vh;
        background-color: $colorWhite;
        overflow-y: auto;
        list-style: none;

        ul {
            list-style: none;
            color: $colorDarkBlue;
            text-transform: none;
        }


        &::-webkit-scrollbar {
            width: 0.5em;
            background-color: $colorLightBlue;
        }

        &::-webkit-scrollbar-thumb {
            // background-color: #888;
            background-color: $colorBlue;
            border-radius: 0.5em;
            // height: 50px;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: $colorDarkBlue;
        }


    }

    .reglament__list-subtitle {
        // margin-left: auto;
        margin-top: 0.5em;
        // width: 98%;
        font-weight: 400;
    }

    .reglament__item-descr {
        margin-bottom: 0.5em;
        ul {
            margin-left: auto;
            list-style: circle;
            width: 95%;

            li {
                line-height: 130%;
            }
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.btn-close-modal {
    align-self: center;
    font-family: Rubik;
    font-size: 1.1vw;
    font-weight: 700;
    text-align: center;
    background: $colorBlue;
    border-radius: 0.5vw;
    width: 25%;
    height: 3vw;
    color: $colorWhite;
    transition: transform .3s ease-in-out;

    &:focus-visible,
    &:hover,
    &:active {
        transform: scale(1.1);
    }

}

.btn-close-modal svg path {
    transition: stroke .3s ease-in-out;
}

// .btn-close-modal:focus-visible svg path,
// .btn-close-modal:hover svg path,
// .btn-close-modal:active svg path {
//     stroke: $btn-decline;
// }

@media (max-width: 1024px) {

    .modal .container {
        padding: 0 16px;
    }

    .modal .modal-container {
        padding: 2.4vw 2.8vw;
        width: 95%;
        border-radius: 16px
    }

    .modal .reglament h1 {
        font-size: 2.4vw;
    }

    .modal .reglament .reglament__item {
        font-size: 2vw;
    }

    .modal .reglament__list::-webkit-scrollbar {
        width: 6px;
    }

    .modal .reglament__list::-webkit-scrollbar-thumb {
        border-radius: 6px;
    }

    .btn-close-modal {
        font-size: 2vw;
        border-radius: 8px;
        width: 40%;
        height: 7vw;
    }

    .modal .reglament__list {
        margin-bottom: 3.5em;
    }
}



@media (max-width: 576px) {

    .modal .container {
        padding: 0 16px;
    }

    .modal .modal-container {
        padding: 4.4vw 4.8vw;
        width: 95%;
        border-radius: 16px
    }

    .modal .reglament h1 {
        font-size: 4.4vw;
    }

    .modal .reglament .reglament__item {
        font-size: 4vw;
    }

    .modal .reglament__list::-webkit-scrollbar {
        width: 6px;
    }

    .modal .reglament__list::-webkit-scrollbar-thumb {
        border-radius: 6px;
    }

    .btn-close-modal {
        font-size: 4vw;
        border-radius: 5px;
        width: 40%;
        height: 9vw;
    }

    .modal .reglament__list {
        margin-bottom: 3.5em;
    }
}

