@import '../../scss/style.scss';

.section-contact {
    padding: 7em 0;
    background-color: $colorYellow;

    .section-contact__wrapp {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            margin-bottom: 0.75em;
            font-family: "Rubik";
            font-size: 3.33vw;
            font-weight: 700;
            color: $colorBlue;
        }

        .section-contact__link {
            margin-bottom: 0.8em;
            font-family: "Rubik";
            font-size: 1.67vw;
            font-weight: 700;
            color: $colorDarkBlue;
            cursor: pointer;
        }
    }

    .section-contact__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        gap: 1em;

        .section-contact__form-input-wrapp {
            position: relative;
            width: 70%;

            .error-message {
                position: absolute;
                bottom: -1em;
                left: 1em;
                font-size: 0.85vw;
                color: $colorRed !important;
            }
        }

        .section-contact__form-input,
        .section-contact__textarea {
            padding: 0.6em 1.65em;
            border-radius: 1.46vw;
            background-color: $colorWhite;
            outline: 2px solid $colorBlue;
            outline-offset: -2px;
            font-family: 'Rubik';
            font-size: 1.1vw;
            font-style: normal;
            font-weight: 400;
            color: $colorDarkBlue;

            &::placeholder {
                color: $colorBlue;
            }
        }

        .section-contact__form-input {
            width: 100%;
        }

        .section-contact__textarea {
            border: none;
            resize: vertical;
            width: 70%;

            &::placeholder {
                color: $colorBlue;
            }
        }

        .section-contact__form-input.error-input {
            outline-color: $colorRed !important;
        }
    }



    .form__btn {
        width: 28%;

        input[type=submit] {
            padding: 0.2em;
            border: none;
            outline: none;
            font-family: "Rubik";
            font-weight: 700;
            font-size: 0.95vw;
            color: $colorWhite;
            border-radius: 1.25vw;
            background-color: $colorBlue;
            cursor: pointer;
            justify-items: center;
            text-align: center;
            width: 100%;
            height: 2.4vw;

            @include button-effect;
        }
    }

    .wrapp-check {
        width: 80%;
    }

    .check {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        font-family: Rubik;
        font-size: 0.84vw;
        font-weight: 400;
        line-height: 100%;
        color: $colorDarkBlue;

        .check-label {
            position: relative;
            display: flex;
            align-self: flex-start;
            margin-right: 1%;
        }

        .check-form {
            position: absolute;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);
        }

        .custom-checkbox-form {
            align-self: flex-start;
            width: 1.06vw;
            min-width: 1.06vw;
            aspect-ratio: 1/1;
            margin-right: 2%;
            cursor: pointer;
            border-radius: 0.4em;
            background-color: $colorWhite;

            @media(max-width: 1920px) {
                border-radius: 5px;
            }
        }

        span {
            width: 100%;
        }

    }

    .check-form:checked+.custom-checkbox-form {
        background-image: url('../../assets/images/check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
    }

    .modal-btn-agree {
        margin-right: 1%;
        color: $colorBlue;
        font-weight: 400;
        font-size: 0.84vw;
    }
}


.val-mess {
    text-align: center;
    color: $colorWhite;
    margin-top: 0.2em;
}

.val-mess.val-mess-error {
    color: $colorRed;
}

.submitDisables {
    opacity: 0.2;
    pointer-events: none;
}

@media (max-width: 1024px) {

    .section-contact .section-contact__wrapp h4 {
        font-size: 4.17vw;
    }

    .section-contact .section-contact__wrapp .section-contact__link {
        font-size: 2.1vw;
    }

    .section-contact .section-contact__form .section-contact__form-input,
    .section-contact .section-contact__form .section-contact__textarea {
        font-size: 2.1vw;
        border-radius: 24px;

        &::placeholder {
            font-size: 2.1vw;
        }
    }

    .section-contact .section-contact__form .section-contact__form-input-wrapp,
    .section-contact .section-contact__form .section-contact__textarea {
        width: 100%;
    }

    .section-contact .section-contact__form .section-contact__form-input-wrapp .error-message {
        font-size: 1.6vw;
    }

    .section-contact .section-contact__form {
        gap: 2.2em;
    }

    .section-contact .check,
    .section-contact .modal-btn-agree  {
        font-size: 1.84vw;
    }

    .section-contact .wrapp-check {
        width: 100%;
    }

    .section-contact .check .custom-checkbox-form {
        width: 2.06vw;
        min-width: 2.06vw;
    }

    .section-contact .form__btn input[type=submit] {
        font-size: 2.34vw;
        border-radius: 24px;
        height: 5.1vw;
    }

    .modal-btn-agree, .val-mess.val-mess-min, .val-mess {
        font-size: 2vw;
    }

}

@media (max-width: 576px) {
    .section-contact {
        padding: 12.2em 0;
    }

    .section-contact .section-contact__wrapp h4 {
        margin-bottom: 0.5em;
        text-align: center;
        font-size: 8.8vw;
    }

    .section-contact .check {
        width: 100%;
        font-size: 3.8vw;
    }

    .section-contact .check, .section-contact .modal-btn-agree {
        font-size: 3.8vw;
    }

    .section-contact .check .custom-checkbox-form {
        width: 5.6vw;
        min-width: 5.6vw;
    }

    .section-contact .check .check-label {
        margin-right: 2%;
    }

    .section-contact .section-contact__wrapp .section-contact__link {
        margin-bottom: 0.5em;
        font-size: 6.6vw;
        text-align: center;
    }

    .section-contact .section-contact__form {
        width: 100%;
        gap: 3em;
    }

    .section-contact .section-contact__form .section-contact__form-input,
    .section-contact .section-contact__form .section-contact__textarea {
        padding: 0.6em 1em;
        font-size: 4.4vw;

        &::placeholder {
            font-size: 4.4vw;
        }
    }


    .section-contact .form__btn {
        width: 56%;
    }

    .section-contact .form__btn input[type=submit] {
        font-size: 4.4vw;
        width: 100%;
        height: 11.5vw;
    }

    .modal-btn-agree, .val-mess.val-mess-min, .val-mess {
        font-size: 3.8vw;
    }

    .section-contact .section-contact__form .section-contact__form-input-wrapp .error-message {
        font-size: 2.8vw;
    }
}