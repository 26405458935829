@import '../../scss/style.scss';

@mixin heading-2 {
  font-family: Rubik, sans-serif;
  font-weight: 700;
  font-size: clamp(18px, 4.8vw, 38px);
  line-height: 100%;
  text-align: center;
  color: $colorDarkBlue;
}

@mixin heading-4 {
  font-family: Rubik, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: $colorBlue;
}

@mixin social {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $colorBlue;
}

.modal-menu {
  @media(min-width: 1025px) {
    display: none;
  }

  ul {
    list-style: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  margin: 0 auto;
  padding: 12.13vw 0;
  width: 100%;
  min-width: 320px;
  height: 100vh;
  background-color: $colorLightBlue;
  z-index: 120;

  @media (orientation: landscape) {
    padding: 0;
  }

  @media (max-width: 576px) {
    padding: 14vw 0 2vw 0;
  }

  .modal-menu-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10em;

    @media (orientation: landscape) {
      gap: 5em;
    }

  }

  .anchor-link {
    @include heading-2;
    text-transform: uppercase;
  }

  .modal-menu-link {
    padding-top: 5.13vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 6em;

    @media (orientation: landscape) {
      gap: 2em;
    }
  }

  .modal-menu-item {
    margin: 0 auto;

    button {
      @include btn-reset;
      border-radius: 2em;
      width: 50vw;
      height: 12.5vw;
      max-height: 76px;

      @include button-effect;

      &:active {
        background-color: $colorDarkBlue;
      }

      a {
        font-size: 4vw;
        font-size: clamp(13px, 4vw, 30px);
        color: $colorWhite;
      }
    }
  }

  .social-list {
    display: flex;
    gap: 16px;
  }

  .social-list__link {
    display: inline-flex;
    width: 9vw;
    max-width: 66px;
    aspect-ratio: 1/1;
  }

}