@import '../../scss/style.scss';

.onas-wrapper {
  position: relative;
  background-color: $colorWhite;
  // height: 100vh;
  overflow: hidden;

  .onas-container {
    position: relative;
    display: flex;
    align-items: center;
    // height: 100%;
    height: 61em;
    z-index: 5;
   
    .img-cleaning-woman {
      background-image: url('../../assets/images/oNas-4.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 50%;
      width: 50%;
      height: 100%;
      // aspect-ratio: 1/1; 
      // margin: 0 auto;
    }

    .oNasInformation {
      padding-left: 40px;
      width: 50%;
    }

    .onas-title {
      margin-bottom: 0.4em;
      font-family: Rubik;
      font-size: 3.34vw;
      font-weight: 700;
      color: $colorBlue;
    }

    .oNasText {
      padding-left: 22px;
      font-family: Rubik;
      font-size: 1.25vw;
      color: $colorDarkBlue;
      line-height: 120%;
      font-weight: 700;
      list-style-type: disc;
    }
  }

}

.back-img-top {
  position: absolute;
  top: -6%;
  left: 0;
  right: 0;
  background-image: url('../../assets/images/blue-bubbles.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    aspect-ratio: 9/1; 
    z-index: 4;
}

.back-img-bottom {
  position: absolute;
  bottom: -6%;
  left: 0;
  right: 0;
  background-image: url('../../assets/images/bubble.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  aspect-ratio: 9/1; 
  z-index: 6;
}


@media(max-width: 1024px) {
  .back-img-top,
  .back-img-bottom {
    display: none;
  }

  .onas-wrapper .onas-container {
    flex-direction: row-reverse;
    height: 50em;
  }

  .onas-wrapper .onas-container .oNasInformation {
    padding-left: 0;
  }

  .onas-wrapper .onas-container .onas-title {
    font-size: 4.17vw;
  }

  .onas-wrapper .onas-container .oNasText {
    font-size: 2.6vw;
  }

  .onas-wrapper .onas-container .img-cleaning-woman {
    height: 150%;
  }
}

@media(max-width: 576px) {
  .onas-wrapper .onas-container {
    flex-direction: column-reverse;
    padding-top: 12.2em;
    height: auto;
    gap: 10em;
  }

  .onas-wrapper .onas-container .img-cleaning-woman {
    width: 150%;
    background-position: 0 -38em;
    aspect-ratio: 2/1;
  }

  .onas-wrapper .onas-container .oNasInformation {
    padding: 0;
    width: 100%;
  }

  .onas-wrapper .onas-container .oNasText {
    padding: 0;
    padding-left: 1em;
    font-size: 5vw;
  }

  .onas-wrapper .onas-container .onas-title {
    margin-bottom: 0.65em;
    font-size: 6.8vw;
  }

  .back-img-top,
  .back-img-bottom {
    width: 200%;
  }

  .back-img-bottom {
    bottom: -2%;
  }

}

