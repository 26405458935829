@import '../../../scss/style.scss';

.client-data-label {
    position: relative;

    .error-message {
        position: absolute;
        bottom: -1.2em;
        color: $colorRed !important;
    }

    .error-input {
        border: 1px solid $colorRed !important;
    }
}