@import '../../scss/style.scss';

@mixin heading-6 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 700;
    // font-size: 18px;
    font-size: 1.4vw;
    line-height: 100%;
    color: $colorWhite;
  }

.section-we {
    padding: 4.2em 0;
    background-color: $colorBlue;
}

.section-we__list {
    display: flex;
    // justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: transparent;
    list-style: none;
    gap: 32px 0;
}

.section-we__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 285px;
    width: 25%;
    text-align: center;
}

.section-we__img {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 32px;
    // width: 70%;
    // height: 70%;
    width: 49%;
    aspect-ratio: 1/1;
    background-color: $colorWhite;
    border-radius: 50%;

    & img {
        align-self: center;
        width: 67%;
        height: 67%;
    }
}

.section-we__descr {
    @include heading-6;
    align-self: center;
    text-align: center;
    width: 74%;
    font-size: 1vw;
}

@media (max-width: 1024px) {
    .section-we__descr {
        font-size: 2vw;
    }

    .section-we__img {
        margin-bottom: 16px;
        width: 68%;
    }

}

@media (max-width: 576px) {
    .section-we {
        padding: 12.2em 0;
    }

    .section-we__list {
        flex-direction: column;
    }

    .section-we__item {
        width: 100%;
    }

    .section-we__img {
        width: 40%;
    }

    .section-we__descr {
        font-size: 4.6vw;
        width: 100%;
    }
}
