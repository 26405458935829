@import '../scss/variables.scss';

@mixin btn-reset {
    padding: 0;
    border: none;
    outline: none;
    font-family: 'Rubik';
    font-weight: 700;
    font-size: 1.4vw;
    color: $colorWhite;
    border-radius: 0.94vw;
    background-color: $colorBlue;
    cursor: pointer;
}

@mixin button-effect {
    transition: all .2s ease-in-out;
    &:focus-visible,
    &:hover,
    &:active {
      box-shadow: $hover_btn;
    }
  }