@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap');
@import '../../scss/style.scss';

@mixin heading-4 {
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

@mixin list-item {
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 500;
  // font-size: 18px;
  font-size: 1.4vw;
  line-height: 21px;
  color: $colorDarkBlue;
}

.back-color {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $colorLightBlue;
  z-index: 130;

}

.header-section {
  height: 100vh;
  background-image: url('../../assets/images/d0752c503021b56607928e0ca11de13b.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;


  @media (max-width: 1024px) {
    background-position: 57%;

    @media (orientation: landscape) {
      height: 100%;
    }
  }

  @media (max-width: 576px) {
    // height: 100%;
    background-position: 0%;
  }

  .hero {
    display: flex;
    align-items: center;
    height: 100%;

    @media (max-width: 1024px) {
      padding: 12.13vw 0;
    }

    @media (max-width: 576px) {
      padding: 14vw 0 2vw 0;
    }

    & .hero__container {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 3em;

      @media (max-width: 1024px) {
        width: 60%;
        gap: 5em;
      }

      @media (max-width: 576px) {
        margin: 0 auto;
        width: 90%;
        gap: 8em;
      }

      h2 {
        font-family: 'Rubik';
        font-size: 5vw;
        font-weight: 700;
        line-height: 90%;
        color: $colorBlue;
        text-shadow: 0px 2px 8px rgba(255, 255, 255, 0.50);

        @media (max-width: 1024px) {
          font-size: clamp(28px, 5.33vw, 48px);
          word-break: break-word;
        }

        @media (max-width: 576px) {
          text-align: center;
          font-size: clamp(28px, 10.33vw, 38px);
          word-break: break-word;
        }
      }

      span {
        color: $colorDarkBlue;
        text-shadow: 1px 0px 40px $colorWhite;
        font-family: 'Rubik';
        font-size: 1.56vw;
        font-weight: 700;

        @media (max-width: 1024px) {
          font-size: clamp(20px, 3.4vw, 32px);
        }

        @media (max-width: 576px) {
          text-align: center;
          font-size: 6.8vw;
          font-size: clamp(20px, 6.8vw, 24px);
        }
      }

      .button {
        @include btn-reset;
        @include button-effect;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35%;
        height: 2.7vw;
        border-radius: 0.94vw;
        font-size: 1vw;

        &:hover {
          box-shadow: 3px 4px 8px 0px rgba(34, 60, 80, 0.2);
        }

        &:active {
          background: $colorDarkBlue;
        }

        a {
          color: $colorWhite;
        }

        @media (max-width: 1024px) {
          border-radius: 8vw;
          width: 50%;
          height: 7.5vw;
          font-size: clamp(16px, 2.34vw, 23px);
        }

        @media (max-width: 576px) {
          margin: 0 auto;
          border-radius: 8vw;
          width: 70%;
          height: 12.5vw;
          font-size: 5vw;
        }
      }
    }
  }
}

header {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 0.3em;
  height: 7vh;
  width: 100%;
  z-index: 20;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));

  @media (max-width: 1024px) {
    height: auto;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;

    @media (max-width: 1024px) {
      justify-content: space-between;
    }
  }

  .header-menu-list {
    display: flex;
    align-items: center;
    gap: 4vw;
  }

  .burger-menu {
    display: none;
    width: 40px;

    @media (max-width: 1024px) {
      display: block;
      width: 5.2vw;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 576px) {
      width: 10.2vw;
      max-width: 46px;
    }
  }

  .header-menu-wrapper {
    display: flex;
    justify-content: space-around;
    // width: 100%;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .header-logo-link {
    width: 13.16vw;
    height: 3.13vw;

    @media (max-width: 1024px) {
      width: 35.16vw;
      height: 12.13vw;

      max-width: 300px;

      @media (orientation: landscape) {
        height: 8.13vw;
        max-width: 200px;
      }
    }


    @media (max-width: 576px) {
      width: 53vw;
      height: 14vw;
      max-width: 250px;

      @media (orientation: landscape) {
        height: 8.13vw;
        max-width: 200px;
      }
    }
  }

  .header-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .social-list {
    gap: 16px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    // width: 320px;
    list-style: none;

    li {
      .anchor-link {
        span {
          @include list-item;
          font-size: 1.25vw;
        }

        @include list-item;
        font-size: 1.25vw;
        // @media (max-width: 1280px) {
        //   font-size: 1.6vw;
        // }
      }

    }
  }

  span {
    color: $colorBlue;
    min-width: 177px;
    @include heading-4;
  }
}

.social-list__link {
  display: inline-flex;
  width: 1.88vw;
  aspect-ratio: 1/1;

  // @media (max-width: 1280px) {
  //   width: 2.4vw;
  // }

  img {
    width: 100%;
    height: 100%;
  }
}