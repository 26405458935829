@import '../../scss/style.scss';

.opinion {
  background-color: $colorWhite;
}

.opinion__wrapp {
  padding: 5.2em 0;
  padding-bottom: 9vw;

  .opinion__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;
    overflow: hidden;
  }

  .opinion__title-shadow {
    font-family: Rubik;
    font-size: 3.75vw;
    font-weight: 700;
    color: $colorWhite;
    -webkit-text-stroke-color: $colorLightBlue;
    -webkit-text-stroke-width: 2px;
    // margin-top: 3em;
    white-space: pre;
    // text-transform: uppercase;
  }

  .opinion__title-text {
    font-family: Rubik;
    font-size: 3.34vw;
    font-weight: 700;
    color: $colorDarkBlue;
    margin-top: -0.6em;
    text-transform: uppercase;
  }
}

.slick-slider {
  display: flex;
  margin-bottom: 1em;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 25%;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  width: 1.2vw;
  height: 3.45vw;
  z-index: 100;

  svg {
    width: 100%;
    height: 100%;
  }
}

.prev-btn {
  left: -2%;
}

.next-btn {
  right: -2%;
}

.slider__item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;

  p {
    width: 90%;
    font-family: 'Rubik';
    font-size: 0.94vw;
    line-height: 100%;
    font-weight: 400;
    color: $colorDarkBlue;
  }
}

.slider__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  outline: 4px solid $colorBlue;
  outline-offset: -4px;
  text-align: center;
  font-family: 'Rubik';
  font-size: 2vw;
  font-weight: 700;
  color: $colorBlue;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.slider__name {
  width: 90%;
  font-family: 'Rubik';
  font-size: 1.25vw;
  font-weight: 700;
  color: $colorDarkBlue;
}

@media (max-width: 1024px) {

  .slider__item {
    gap: 2em;
  }

  .opinion__wrapp .opinion__title-shadow {
    display: none;
  }

  .opinion__wrapp .opinion__title-text {
    font-size: 4.47vw;
    margin-top: 0;
  }

  .prev-btn,
  .next-btn {
    width: 3.2vw;
    height: 5.45vw;
  }

  .slider__img {
    width: 13vw;
    height: 13vw;
    font-size: 6vw;
  }

  .slider__name {
    font-size: 3vw;
  }

  .slider__item p {
    font-size: 2.2vw;
  }

  .opinion__wrapp {
    padding-bottom: 6vw;
  }
}


@media (max-width: 576px) {

  .prev-btn,
  .next-btn {
    width: 7.2vw;
    height: 9.45vw;
  }

  .opinion__wrapp .opinion__title-text {
    font-size: 6.8vw;
    margin-top: 0;
  }

  .slider__img {
    width: 25vw;
    height: 25vw;
    font-size: 7vw;
  }

  .slider__name {
    font-size: 5.6vw;
  }

  .slider__item p {
    font-size: 4vw;
  }

  .slider__item {
    gap: 5em;
  }

  .opinion__wrapp {
    padding-bottom: 28vw;
  }
}